// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore/lite'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getAuth, connectAuthEmulator } from 'firebase/auth'

const firebaseConfig = {
    apiKey: import.meta.env.VITE_API_KEY,
    authDomain: import.meta.env.VITE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_PRODUCT_ID,
    storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APPID,
    measurementId: import.meta.env.VITE_MEASUREMENT_ID
}

const REGION = 'europe-west2'
let apiEndpoint = `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net/api`

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)
// set function region, otherwise it will have CORS error
// const functions = getFunctions(app, REGION)
const auth = getAuth()

const isDevMode = import.meta.env.MODE
if (isDevMode === 'development') {
    const isRunFirebaseFunctionEmulator = import.meta.env.VITE_RUN_FIREBASE_FUNCTION_EMULATOR

    if (isRunFirebaseFunctionEmulator === 'true') {
        console.log('Running Firebase Function Emulator')
        // connectFunctionsEmulator(functions, '127.0.0.1', 5001)
        connectFirestoreEmulator(db, '127.0.0.1', 8088)
        connectAuthEmulator(auth, 'http://127.0.0.1:9099')
        connectStorageEmulator(storage, '127.0.0.1', 9199)
        apiEndpoint = `http://127.0.0.1:5001/${firebaseConfig.projectId}/${REGION}/api`
    } else {
        console.log('NOT EMULATING Firebase Functions')
    }
}

const actionCodeSettings = {
    url: `${window.location.origin}/auth/action`,
    handleCodeInApp: true
}

export { db, auth, storage, apiEndpoint, actionCodeSettings }
