import { createWebHistory, createRouter } from 'vue-router'
import { auth } from '@/firebase/config'
import { useAuthStore } from '@/stores/AuthStore'

const requireAuth = async (to, from, next) => {
    console.log('requireAuth !!!!!!')
    const authStore = useAuthStore()

    const currentUser = await authStore.getCurrentUser()

    if (currentUser !== null) {
        // check if the user is not admin and trying to access admin page
        if (to.path.startsWith('/admin') && currentUser.claims.role !== 'admin') {
            console.log('Not Allowed')
            next('/404')
        }
        // pass check, then go to next
        next()
    } else {
        next('/login')
    }
}

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginView.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/ForgotPassword.vue')
    },
    {
        path: '/auth/action',
        name: 'ResetPassword',
        component: () => import('@/views/ResetPassword.vue')
    },
    {
        path: '/',
        name: 'Home',
        redirect: { name: 'Login' }
    },
    {
        path: '/website/:ruid',
        name: 'RestaurantLayout',
        beforeEnter: requireAuth,
        component: () => import('@/layouts/RestaurantLayout.vue'),
        children: [
            {
                path: '',
                name: 'RestaurantWebsiteEdit',
                component: () => import('@/views/RWebsiteView.vue')
            }
        ]
    },
    {
        path: '/admin',
        name: 'AdminLayout',
        beforeEnter: requireAuth,
        component: () => import('@/layouts/AdminLayout.vue'),
        children: [
            {
                path: '',
                name: 'AdminHome',
                component: () => import('@/views/AHome.vue')
            },
            {
                path: 'user',
                name: 'AdminUser',
                children: [
                    {
                        path: 'create',
                        name: 'AdminUserCreate',
                        component: () => import('@/views/AUserCreate.vue')
                    },
                    {
                        path: 'list',
                        name: 'AdminUserList',
                        component: () => import('@/views/AUserList.vue')
                    },
                    {
                        path: 'detail/:uid',
                        name: 'AUserDetail',
                        component: () => import('@/views/AUserDetail.vue')
                    }
                ]
            },
            {
                path: 'admin',
                name: 'AdminAdmin',
                children: [
                    {
                        path: 'list',
                        name: 'AdminAdminList',
                        component: () => import('@/views/AAdminList.vue')
                    }
                ]
            },
            {
                path: 'restaurant',
                name: 'AdminRestaurant',
                children: [
                    {
                        path: 'create',
                        name: 'AdminRestaurantCreate',
                        component: () => import('@/views/ARestaurantCreate.vue')
                    },
                    {
                        path: 'list',
                        name: 'AdminRestaurantList',
                        component: () => import('@/views/ARestaurantList.vue')
                    },
                    {
                        path: 'detail/:ruid',
                        name: 'ARestaurantServiceConfig',
                        component: () => import('@/views/ARestaurantServiceConfig.vue')
                        // props: true
                    },
                    {
                        path: 'website/update/:ruid',
                        name: 'AdminRestaurantWebsiteUpdate',
                        component: () => import('@/views/RWebsiteView.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/NotFound.vue')
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import('@/views/NotFound.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
